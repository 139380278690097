<template>
  <fade-slide>
    <div class="count-step" :id="$options.formInfo.id">
      <div class="content">
        <upper-text class="question">
          Quantos colaboradores tem na sua empresa?
        </upper-text>
          <ul class="count-list">
            <li
              v-for="count in $options.counts"
              :key="count"
              class="count-item"
              @click="onClick(count)"
            >
              <span class="value">{{ count }}</span>

              <div class="slide-wrapper">
                <div class="slide">
                  <img :src="require('@assets/arrow-right.svg')" class="arrow-right -ghost" />
                  <img :src="require('@assets/arrow-right.svg')" class="arrow-right" />
                </div>
              </div>
            </li>
          </ul>

        <prev-button class="prev-button" step-num="3" @click="$emit('prev')" />
      </div>
    </div>
  </fade-slide>
</template>

<script>
import FadeSlide from '@components/transitions/FadeSlide'
import counts from '@content/forms/count'
import { count as formInfo } from '@content/forms/ids/index'
import PrevButton from './PrevButton'
import UpperText from './UpperText'

export default {
  counts,
  formInfo,

  components: {
    PrevButton,
    UpperText,
    FadeSlide
  },

  methods: {
    onClick (count) {
      this.$emit('update:count', count)
      this.$emit('next')
    }
  }
}
</script>

<style lang="scss">
@keyframes arrowSlideRight {
  from { transform: translateX(-42px); }
  to { transform: translateX(0px); }
}

.count-step {
  display: flex;
  justify-content: flex-start;

  & > .loader {
    width: 120px;
    margin-top: 30px;
  }
}

.count-step > .content {
  width: 100%;

  & > .upper-text {
    margin-bottom: 20px;

    @include responsive (xs-mobile, mobile) {
      max-width: 200px;
      line-height: 24px;
    }
  }

  & > .prev-button {
    margin-top: 55px;

    @include responsive (xs-mobile, mobile) {
      margin-top: 32px;
    }

    @include responsive (xs-mobile, mobile) {
      width: 100%;
    }
  }

  & > .count-list {
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 2px 8px 16px 0 rgba(24,50,115,0.06);
    overflow: hidden;
    transition: opacity 300ms;

    &.-disabled {
      pointer-events: none;
      user-select: none;
      opacity: .6
    }

    & > .count-item {
      height: 59px;
      display: flex;
      align-items: center;
      background-color: rgba($base-text-color, 0.00);
      transition: background-color 200ms;

      &:hover > .slide-wrapper > .slide {
        animation-play-state: running;
        animation-iteration-count: infinite;

        & > .arrow-right {
          opacity: 0.7;
        }
      }

      & > .slide-wrapper {
        width: 24px;
        overflow: hidden;
        margin-right: 18px;
        margin-left: auto;
      }

      & > .slide-wrapper > .slide {
        transform: translateX(-42px) !important;
        display: flex;
        align-items: center;

        animation-name: arrowSlideRight;
        animation-iteration-count: 1;
        animation-play-state: paused;
        animation-duration: 1s;
        animation-timing-function: cubic-bezier(0.83,-0.01, 0.58, 1);
      }

      & > .slide-wrapper > .slide > .arrow-right {
        width: 24px;
        height: 24px;
        margin-left: auto;
        margin-right: 18px;
        opacity: 0.5;
        transition: opacity 200ms;
      }

      & > .value {
        padding-left: 20px;
        opacity: 0.7;
        color: $base-text-color;
        font-family: $font-family-nunito-bold;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 15px;
        -webkit-font-smoothing: antialiased;
        text-transform: uppercase;
        transition: opacity 200ms;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid rgba($base-text-color, 0.1);
      }

      &:last-of-type { height: 60px; }

      &:hover {
        background-color: rgba($base-text-color, 0.05);
        cursor: pointer;
      }

      &:hover > .value {
        opacity: 0.9;
      }

      &:hover > .arrow-right {
        opacity: 0.7;
      }
    }
  }
}
</style>
