import { InputPassword } from '@inputs'

export default {
  password: {
    component: InputPassword,
    placeholder: 'NO MÍNIMO 8 CARACTERES UTILIZANDO LETRAS E NÚMEROS',
    placeholderMobile: 'No mínimo 8 caracteres + letras e números',
    strengths: {
      weak: {
        strength: '40',
        message: 'Senha fraca',
        color: '#FF4B8C'
      },
      moderate: {
        strength: '120',
        message: 'Pode melhorar',
        color: '#FFC24B'
      },
      strong: {
        strength: '_',
        message: '',
        color: '#63E1A5'
      }
    },
    hasStrengthMeter: true,
    validation: 'required|min:8|password'
  },
}
