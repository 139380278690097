const createPutPayload = ({
  company,
  count
}) => {
  const { companyName } = company || {}

  return {
    ...(companyName ? { company_name: companyName, step: 2 } : {}),
    ...(count ? { employees_count: count, step: 3 } : {})
  }
}

export const getUser = async (request, id) => {
  const [ err, response ] = await request.get(`/registrations/${id}`)
  const { data } = response || {}
  return [ err, data ]
}

export const putUser = async (request, id, userData) => {
  const putPayload = createPutPayload(userData)
  const [ err, response ] = await request.put(`/registrations/${id}`, putPayload)
  return [ err, response ]
}

export const finish = async (request, id, password) => {
  const payload = { password, step: 4 }

  const [ error, response ] = await request.post(`/registrations/${id}/finish`, payload)
  return [ error, response?.registration || {} ]
}
