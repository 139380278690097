<template>
  <div class="company-step">
    <span class="info">
      Precisamos que responda algumas perguntas para prepararmos a
      plataforma para você. ok?
    </span>

    <form-builder
      class="form"
      :fields="$options.fields"
      :value="value"
      :id="$options.formInfo.id"
      @input="data => $emit('input', data)"
      @submit="({ handleSubmit }) => handleSubmit(onSubmit)"
    >
      <submit-button slot="after-fields" :loading="loading" class="submit-step-1">
        avançar
      </submit-button>
    </form-builder>
  </div>
</template>

<script>
import FormBuilder from '@components/form/Builder'
import SubmitButton from '@components/form/SubmitButton'
import fields from '@content/forms/company'
import { company as formInfo } from '@content/forms/ids/index'

import Step from './Step'

export default {
  mixins: [ Step ],

  fields,
  formInfo,

  components: {
    FormBuilder,
    SubmitButton
  },
}
</script>

<style lang="scss">
.company-step {
  & > .form > .submit-button {
    width: 100%;
    margin-top: 40px;

    @include responsive (xs-mobile, mobile) {
      margin-top: 32px;
    }
  }

  & > .info {
    display: inline-block;
    margin-bottom: 30px;
    color: $base-text-color;
    font-family: $font-family-roboto-bold;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 42px;
    opacity: 0.9;

    @include responsive (xs-mobile, mobile) {
      max-width: 300px;
      line-height: 29px;
      font-size: 22px;
    }
  }
}
</style>
