export default {
  props: {
    value: [ Object, String ],
    loading: Boolean
  },

  methods: {
    onSubmit () {
      this.$emit('next')
    },

    onPrev () {
      this.$emit('prev')
    }
  }
}
