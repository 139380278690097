<template>
  <div class="steps">
    <span class="current-indicator">
      Passo {{ numStep }} / 3
    </span>

    <fade-slide :direction="direction">
      <company-step
        v-show="step === 'company' && showStep"
        :loading="loading"
        v-model="company"
        @next="onSubmit('company', 'count')"
      />
    </fade-slide>

    <fade-slide :direction="direction">
      <count-step
        v-show="step === 'count' && showStep"
        :count.sync="count"
        @next="onSubmit('count', 'password')"
        @prev="prev('company')"
      />
    </fade-slide>

    <fade-slide :direction="direction">
      <password-step
        v-show="step === 'password' && showStep"
        :loading="loading"
        v-model="password"
        @next="submitPassword"
        @prev="prev('count')"
      />
    </fade-slide>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import FadeSlide from '@components/transitions/FadeSlide'
import delay from '@modules/delay'
import name from '@router/names'
import { putUser, finish } from '@services/steps'

import PasswordStep from '@components/Steps/PasswordStep'
import CompanyStep from '@components/Steps/CompanyStep'
import CountStep from '@components/Steps/CountStep'

const exists = (obj, keys = []) => (keys || [])
  .every(key => Object.keys(obj).includes(key) && !!obj[key])

export default {
  components: {
    FadeSlide,
    CompanyStep,
    PasswordStep,
    CountStep,
  },

  props: {
    user: Object,
    cookieDomain: String
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler: 'getInitialUserState'
    }
  },

  data: () => ({
    step: 'company',
    showStep: true,
    direction: 'left',
    company: {
      companyName: '',
      phone: ''
    },
    count: '',
    password: '',
    loading: false,
  }),

  computed: {
    numStep () {
      return {
        'company': 1,
        'count': 2,
        'password': 3
      }[this.step]
    }
  },

  methods: {
    getCurrentStep () {
      if (exists(this.user, [ 'employees_count' ])) return 'password'
      if (exists(this.user, [ 'company_name', 'phone' ])) return 'count'

      return 'company'
    },

    assignStepDirection (step, direction) {
      Object.assign(this.$data, {
        step,
        direction,
        showStep: true
      })
    },

    assignShowDirection (direction) {
      Object.assign(this.$data, {
        direction,
        showStep: false
      })
    },

    async next (step) {
      this.assignShowDirection('right')
      await delay(400)
      this.assignStepDirection(step, 'left')
      this.loading = false
    },

    async prev (step) {
      this.assignShowDirection('left')
      await delay(400)
      this.assignStepDirection(step, 'right')
    },

    async submitPassword () {
      this.loading = true
      const [ err, cookies = {} ] = await finish(this.request, this.user.id, this.password)
      if (err) return this.$router.push({ name: name.ERROR })()

      Object.entries(cookies).forEach(([ name, value ]) => {
        Cookie.set(name, value, { domain: this.cookieDomain })
      })

      this.$emit('success')
    },

    async onSubmit (type, nextType) {
      const input = { [type]: this[type] }
      this.loading = true
      const [ err ] = await putUser(this.request, this.user.id, input)
      if (err) return this.$router.push({ name: name.ERROR })()

      this.next(nextType)
    },

    getInitialUserState (value) {
      const user = value || {}
      Object.assign(this.$data, {
        company: {
          companyName: user.company_name || '',
          phone: user.phone || ''
        },
        count: user.employees_count || ''
      })
    },
  },

  created () {
    this.step = this.getCurrentStep()
  },

  inject: [ 'request' ]
}
</script>

<style lang="scss">
.steps {
  & > .current-indicator {
    opacity: 0.8;
    color: $base-text-color;
    font-family: $font-family-nunito-regular;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 8px;
    display: inline-block;
  }

  & > .loader {
    width: 60px;
    position: absolute;
    margin-top: 30px;
  }
}
</style>
