<template>
  <div v-if="user" :class="[ 'view-questions', { '-full-content': success  } ]">
    <aside-info
      v-if="!isMobile"
      :title="`Olá, ${user.name}!`"
      class="aside-info"
    >
      <img
        slot="before"
        :src="require('@assets/logo-white.svg')"
        class="logo"
      />

      <span class="message">
        Falta pouco para você revolucionar o RH da sua empresa!
      </span>
    </aside-info>

    <main class="content">
      <navbar v-if="isMobile" class="navbar" :home-url="homeUrl" />

      <fade-slide>
        <!-- Main cointaner -->
        <steps-container
          v-if="!success"
          class="steps"
          :user="user"
          :cookie-domain="cookieDomain"
          @success="onSuccess"
         />

        <preparation-stage v-else/>
      </fade-slide>

      <copyright v-if="!isMobile" />
    </main>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import { getUser } from '@services/steps'
import delay from '@modules/delay'
import AsideInfo from '@components/layout/AsideInfo'
import Copyright from '@components/layout/Copyright'
import StepsContainer from '@containers/Steps'
import FadeSlide from '@components/transitions/FadeSlide'
import PreparationStage from '@components/Steps/PreparationStage'
import Navbar from '@components/layout/Navbar'

export default {
  mixins: [ MediaQuery ],

  components: {
    AsideInfo,
    Copyright,
    StepsContainer,
    FadeSlide,
    PreparationStage,
    Navbar
  },

  props: {
    cookieDomain: String,
    homeUrl: String,
    loginUrl: String,
    appUrl: String
  },

  data: () => ({ success: false, user: null }),

  async beforeRouteEnter (to, from, next) {
    const { id } = to.params
    next(async vm => {
      const [ err, registration ] = await getUser(vm.request, id)
      if (!err) vm.user = registration
      else vm.$router.push('/')
    })
  },

  methods: {
    async onSuccess () {
      this.success = true
      await this.redirectApp()
    },

    async redirectApp () {
      await delay(3000)
      window.location = this.appUrl
    }
  },

  inject: [ 'request', 'loginUrl' ]
}
</script>

<style lang="scss">
.view-questions {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  //height: unset;
  height: 100%;

  @include responsive (tablet, desktop) {
    grid-template-columns: auto 1fr;
    height: 100%;
    overflow-x: hidden;
    transform: translateX(0);
    transition: transform .5s, width .5s;

    &.-full-content {
      width: calc(100% + #{$aside-width});
      transform: translateX(-#{$aside-width});
    }
  }

  &.-full-content > .content {
    background-image: url('~@assets/bg_final_step.png');
  }

  & > .aside-info {
    flex-basis: $aside-width;
    padding: 120px 80px;

    & > .logo {
      position: absolute;
      top: 32px;
      left: 32px;

      width: 102px;
      height: 33px;
      z-index: 10;
    }

    & > .title {
      align-self: flex-start;
    }

    & > .message {
      margin-top: 16px;
      opacity: 0.9;
      color: #FFFFFF;
      font-family: $font-family-nunito-regular;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 32px;
      -webkit-font-smoothing: antialiased;
    }
  }

  & > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('~@assets/bg-trial.png');
    background-repeat: no-repeat;
    background-size: cover;

    @include responsive (xs-mobile, mobile) {
      padding: 0 16px;
    }

    & > .navbar {
      padding: 22px 0 0 7px;
      align-self: flex-start;
    }

    & > .steps {
      width: 508px;
      margin-top: 160px;

      @include responsive (xs-mobile, mobile) {
        width: 100%;
        max-width: 508px;
        margin-top: 60px;
      }
    }
  }
}
</style>
