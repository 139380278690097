<template>
<div class="prev-next">
  <prev-button class="prev" v-on="$listeners"/>

  <submit-button
     :class="['next', ...(stepNum ? [`submit-step-${stepNum}`] : [])]"
     v-bind="$attrs"
     :loading="loading"
  >
    <slot />
  </submit-button>
</div>
</template>

<script>
import SubmitButton from '@components/form/SubmitButton'
import PrevButton from './PrevButton'

export default {
  components: { SubmitButton, PrevButton },

  props: { stepNum: String, loading: Boolean }
}
</script>

<style lang="scss">
.prev-next {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  & > .submit-button {
    width: 240px;
  }

  @include responsive (xs-mobile, mobile) {
    margin-top: 32px;
    flex-direction: column-reverse;
    & > .submit-button, & > .prev-button {
      width: 100%;
    }

    & > .submit-button {
      margin-bottom: 15px;
    }
  }
}
</style>
