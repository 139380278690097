import { InputText } from '@inputs'

export default {
  companyName: {
    component: InputText,
    style: { 'margin-bottom': '15px' },
    validation: 'required',
    type: 'text',
    placeholder: 'nome da empresa',
    hasLabelTag: true,
  }
}
